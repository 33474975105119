import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
  standalone: true,
})
export class PhonePipe implements PipeTransform {
  transform(rawNum: string | number) {
    const normalizedNum: string = typeof rawNum === 'number' ? rawNum.toString() : rawNum;

    if (normalizedNum?.length !== 10) {
      return rawNum; // do nothing if if it's not a 10-digit number
    }

    const areaCode = normalizedNum.substring(0, 3);
    const centralOfficeCode = normalizedNum.substring(3, 6);
    const lineNumber = normalizedNum.substring(6, 10);

    return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  }
}
